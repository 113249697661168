
import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";


const AddVehicle = (props) => {

    const {t} = useTranslation();
    return (


        <div className="card mt-5">

            <header className="card-header">
                <p className="card-header-title">
                    {t('add_new_vehicle')}
                </p>

            </header>

            <div className="card-content">

                <div className="content">
                    <form onSubmit={props.handleSubmitFunction}>


                        <div className={'columns'}>

                            <div className={'column'}>
                                <div className="field">
                                    <label className="label">{t('make_and_model')}</label>
                                    <div className="control">
                                        <input required className="input" type="text"    value={props.makeAndModel}   onChange={(e) => props.setMakeAndModel(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                            <div className={'column'}>
                                <div className="field">
                                    <label className="label">{t('reg_number')}</label>
                                    <div className="control">
                                        <input required className="input" type="text"    value={props.regNumber}   onChange={(e) => props.setRegNumber(e.target.value)}/>
                                    </div>
                                </div>
                            </div>

                        </div>






                        <div className={'columns is-multiline'}>
                            {  props.vehicleCategories.map(vehicleCategory => (
                                <div key={(vehicleCategory.id)}  className={'column is-4 has-text-centered'}>
                                    <div className="box">


                                        <div className={'columns'}>


                                            <div className={'column is-narrow'}>
                                                <img   style={{'height': `100px`}}  src={`https://s3.eu-north-1.amazonaws.com/assets.washcar/img/svg/${vehicleCategory.image}`}  />
                                            </div>

                                            <div className={'column'}>
                                                <label className="radio">
                                                    <input required   checked={parseInt(props.categoryId) === parseInt(vehicleCategory.id)} value={(vehicleCategory.id)} onChange={e => props.setCategoryId(e.currentTarget.value)} id={'vehicle_category_' + vehicleCategory.id} name={'service_id'} type="radio"/>
                                                    <span className={'ml-1'}>{(vehicleCategory.label)}</span>

                                                </label>
                                            </div>


                                        </div>



                                    </div>
                                </div>
                            ))}
                        </div>







                        <div className="field is-grouped mt-4">
                            <div className="control">
                                <button disabled={props.submitDisabled} type="submit" className="button is-link">{t('add')}</button>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div>






    );
};

export default AddVehicle;